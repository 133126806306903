import { Sortable } from 'sortablejs';
import ApplicationController from '../editor/application_controller';
import { createRequest } from '../helpers/createRequest';

export default class extends ApplicationController {
  connect() {
    const tabs = document.querySelector('nav ul.tabs');
    const assignments = document.querySelectorAll('nav ul.assignments');
    new Sortable(tabs, {
      animation: 150,
      group: 'tabs',
      filter: '.dropdown,.group-formation',
      preventOnFilter: true,
      onEnd: (evt) => {
        const sortedTabs = evt.target.querySelectorAll(':scope > li:not(.group-formation)');
        console.log(sortedTabs);
        const pos = [...sortedTabs].map((el, index) => {
          const id = el.id.split('-')[1];
          return { id, ordinal: index + 1 };
        });

        fetch(createRequest('/x1/sections/positions', 'POST', JSON.stringify(pos))).then(
          () => Turbolinks.clearCache()
        )
      },
    });

    assignments.forEach((assignment) => {
      new Sortable(assignment, {
        animation: 150,
        group: 'assignments',
        onEnd: (evt) => {
          if (evt.to === evt.from) {
            const sortedAssignments = evt.to.querySelectorAll('li');
            const pos = [...sortedAssignments].map((el, index) => {
              const id = el.id.split('-')[1];
              return { id, position: index + 1 };
            });
            fetch(createRequest('/x1/assignments/positions', 'POST', JSON.stringify(pos))).then(
              () => Turbolinks.clearCache()
            );
          }
        },
      });
    });
  }
}
